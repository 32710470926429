import axios from 'axios'
const state = {
    terms: [],
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getTerms(state) {
        return state.terms
    },
    getTableOptions(state) {
        return state.tableOptions
    },
};

const mutations = {
    setTerms(state, terms) {
        state.terms = terms
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
};

const actions = {
    async fetchTerms({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
                name: queryParams.nameSearch,
                term_type_id: queryParams.searchTermTypeId,
                educational_year_id: queryParams.searchEducationalYearId,
            };
            if (queryParams.paginationValue) {
                params.page = queryParams.paginationValue;
            }
            if (!queryParams.nameSearch) {
                delete params.name;
            }
            if (!queryParams.searchTermTypeId) {
                delete params.term_type_id;
            }
            if (!queryParams.searchEducationalYearId) {
                delete params.educational_year_id;
            }
            const response = await axios.get("/term", {
                params,
            });
            commit('setTerms', response.data.terms)
            commit('setTableData', {
                tableHeaders: [
                    "id",
                    "name",
                    "educational year",
                    "term type",
                    "settings",
                ],
                tableData: response.data.terms,
                accessTableData: [
                    ["id"],
                    ["name"],
                    ["educational_year", "name"],
                    ["term_type", "name"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchAllTerms({ commit }) {
        try {
            const terms = await axios.get("/term", {
                params: {
                    size: 1000,
                },
            });
            commit('setTerms', terms.data.terms)
        }
        catch (err) { }
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}