import axios from 'axios'
import i18n from '@/i18n';
import {confirmDialog} from "@/helpers/sweetalert";

const state = {
  options: {},
  data: [
    {
      "id": 2,
      "name": "docs 1",
      "cash_account_name": "account_2",
      "type": "loan",
      "date": "2023-01-01",
      "note": "note1",
      "details": []
    }
  ],
  url: '/hr/document',
  postUrl: '/hr/document/loans',
  loading: false,
  headers: [
    {text: i18n.t('hr.time.date'), value: 'name'},
    {text: i18n.t('hr.vacation.note'), value: 'note'},
    {text: i18n.t('hr.loan.cash'), value: 'cash_account.name'},
    {text: i18n.t('hr.loan.stop-date'), value: 'stop_date'},
  ]
}
const getters = {
  data(state) {
    return state.data
  },
  headers(state) {
    return [...state.headers, {text: '', value: 'actions'}]
  }
}
const mutations = {}
const actions = {
  async fetch({commit}, queryParams) {
    try {
      state.loading = true
      let params = {
        page: state.options?.page ?? 1,
        perPage: state.options?.itemsPerPage ?? 10,
        per_page: state.options?.itemsPerPage ?? 10,
      };
      const response = await axios.get(`${state.url}?type=loan`, {
        params,
      });
      state.data = response.data.documents
      state.serverTotal = response.data.meta.total
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async store({commit, state, dispatch}, data) {
    console.log(data)
    try {
      state.loading = true
      await axios.post(`${state.postUrl}`, data);
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async update({commit, state, dispatch}, data) {
    try {
      state.loading = true
      await axios.put(`${state.postUrl}/${data.id}`, data);
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async delete({commit, state, dispatch}, id) {
    try {
      state.loading = true
      if (await confirmDialog()) {
        await axios.delete(`${state.url}/${id}`);
        dispatch('fetch')
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
