import { action, computed, observable } from 'mobx';
export class State {

    constructor(value) {
        this.value = value | {};
    }
    @observable success = false;
    @observable loading = false;
    @observable error = null;
    @observable valid = null;
    @observable value = {};
    lock = false;

    @action async trigger(cb, { onSuccess, onError, clearonError } = {}) {
        // if (this.lock) throw new Error("You Can't Trigger Locked State")
        // this.lock = true;
        this.error = null;
        this.loading = true;
        try {
            this.value = await cb();
            if (onSuccess) {
                onSuccess(this.value)
                this.success = true
            };
        } catch (error) {
            this.error = error;
            if (clearonError) this.value = {};
            if (onError) onError(error);
        } finally {
            this.loading = false;
            // this.lock = false;
        }
    }

}
