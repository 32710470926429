import axios from "axios";
const state = {
  tableOptions: {
    tableHeaders: [],
    tableData: [],
    accessTableData: [],
    loading: false,
    tableMeta: {
      total: 10,
      page: 1,
      perPage: 5,
    },
  },
  tableOptionsParents: {
    tableHeaders: [],
    tableData: [],
    accessTableData: [],
    loading: false,
    tableMeta: {
      total: 10,
      page: 1,
      perPage: 5,
    },
  }
};
const getters = {
  getTableOptions(state) {
    return state.tableOptions
  },
  getTableOptionsParents(state) {
    return state.tableOptionsParents
  },
};
const mutations = {
  setLoading(state, status) {
    state.tableOptions.loading = status
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders
    state.tableOptions.tableData = tableInfo.tableData
    state.tableOptions.accessTableData = tableInfo.accessTableData
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    state.tableOptions.tableMeta.perPage = tableInfo.tableMeta.per_page
  },
  setLoadingParents(state, status) {
    state.tableOptionsParents.loading = status
  },
  setTableDataParents(state, tableInfo) {
    state.tableOptionsParents.tableHeaders = tableInfo.tableHeaders
    state.tableOptionsParents.tableData = tableInfo.tableData
    state.tableOptionsParents.accessTableData = tableInfo.accessTableData
    state.tableOptionsParents.tableMeta.total = tableInfo.tableMeta.total
    state.tableOptionsParents.tableMeta.page = tableInfo.tableMeta.page
    state.tableOptionsParents.tableMeta.perPage = tableInfo.tableMeta.per_page
  },
};
const actions = {
  async fetchAccountantFinancialOrder({ commit }, queryParams) {
    try {
      commit('setLoading', true)
      let params = {
        size: 10,
        from: queryParams.tableFilter.from,
        to: queryParams.tableFilter.to,
        id: queryParams.tableFilter.id,
        amount: queryParams.tableFilter.amount,
        action: queryParams.tableFilter.action,
        account: queryParams.tableFilter.account,
        desc: queryParams.tableFilter.desc,
        done: queryParams.tableFilter.done,
        costCenter: queryParams.tableFilter.costCenter,
        box: queryParams.tableFilter.box
      };
      if (queryParams.paginationValue) {
        params.page = queryParams.paginationValue;
      }
      for (let query in queryParams.tableFilter) {
        if (!queryParams.tableFilter[query]) delete params[query];
      }

      const response = await axios.get("/accounting/financial-order", {
        params
      });
      response.data.data.forEach(el => {
        if (el.done) {
          el.done = "accepted";
        }
        if (el.action == "deposit") {
          el.localeAction = "deposit";
        } else {
          el.localeAction = "pay";
        }
      });
      commit(
        "setTableData",
        {
          tableHeaders: [
            "number",
            "command type",
            "value",
            "box",
            "account name",
            "explain",
            "cost center",
            "user name",
            "date",
            "status",
            "settings"
          ],
          tableData: response.data.data,
          accessTableData: [
            ["id"],
            ["localeAction"],
            ["amount"],
            ["box", "name"],
            ["account", "name"],
            ["desc"],
            ["cost_center", "name"],
            ["generator", "username"],
            ["date"],
            ["done"]
          ],
          tableMeta: {
            total: response.data.meta.total,
            page: response.data.meta.current_page,
            per_page: response.data.meta.per_page,
          }
        }
      );
    } catch (err) {
      console.log("err", err);
    } finally {
      commit('setLoading', false)
    }
  },
  async fetchFinancialOrderParents({ commit }, queryParams) {
    try {
      commit('setLoadingParents', true)
      let params = {
        size: 10,
        from: queryParams.tableFilter.from,
        to: queryParams.tableFilter.to,
        registration_record_id: queryParams.tableFilter.registration_record_id,
        parent_id: queryParams.tableFilter.parent_id,
        amount: queryParams.tableFilter.amount,
        desc: queryParams.tableFilter.desc,
        status: queryParams.tableFilter.status,
      };
      if (queryParams.paginationValue) {
        params.page = queryParams.paginationValue;
      }
      for (let query in queryParams.tableFilter) {
        if (!queryParams.tableFilter[query]) delete params[query];
      }

      const response = await axios.get("/accounting/app-financial-order", { params });
      // response.data.data.forEach(el => {
      //   if (el.done) {
      //     el.done = "accepted";
      //   }
      //   if (el.action == "deposit") {
      //     el.localeAction = "deposit";
      //   } else {
      //     el.localeAction = "pay";
      //   }
      // });
      commit(
        "setTableDataParents",
        {
          tableHeaders: [
            "id",
            "date",
            "student name",
            "father name",
            "user name",
            "value",
            "status",
            "explain",
            "settings"
          ],
          tableData: response.data.data,
          accessTableData: [
            ["id"],
            ["date"],
            ["student"],
            ["reciever","name"],
            ["generator", "name"],
            ["amount"],
            ["status"],
            ["desc"],
          ],
          tableMeta: {
            total: response.data.meta.total,
            page: response.data.meta.current_page,
            // per_page: response.data.meta.per_page,
          }
        }
      );
    } catch (err) {
      console.log("err", err);
    } finally {
      commit('setLoadingParents', false)
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
