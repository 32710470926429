import axios from "axios";
const state = {
  eduClasses: [],
  loading: false,
  eduClassInfo: {},
  tableOptions: {
    tableHeaders: [],
    tableData: [],
    accessTableData: [],
    loading: false,
    tableMeta: {
      total: 10,
      page: 1,
    },
  },
};
const getters = {
  getEduClasses(state) {
    return state.eduClasses;
  },
  getLoading(state) {
    return state.loading;
  },
  getEduClassInfo(state) {
    return state.eduClassInfo;
  },
  getTableOptions(state) {
    return state.tableOptions;
  },
};
const mutations = {
  setEduLoading(state, status) {
    state.loading = status;
  },
  setEduClasses(state, eduClasses) {
    state.eduClasses = eduClasses;
  },
  setEduClassInfo(state, eduClassInfo) {
    state.eduClassInfo = eduClassInfo;
  },
  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchEducationClass({ commit }, payload) {
    try {
      commit("setLoading", true);
      commit("setEduLoading", true);
      let params = {
        size: 10,
        page: payload.page,
        paginated: payload.paginated,
      };
      for (let key in payload.params) {
        if (payload.params[key]) {
          params[key] = payload.params[key];
        }
      }
      const response = await axios.get("/edu-class", {
        params,
      });
      commit("setEduClasses", response.data.classes);
     if(payload.paginated){
      commit("setTableData", {
        tableHeaders: [
          "id",
          "education class name",
          "room",
          "mainview.class",
          "total students",
          "settings",
        ],
        tableData: response.data.classes,
        accessTableData: [
          ["id"],
          ["name"],
          ["room", "name"],
          ["grade", "name"],
          ["registration_record_count"],
        ],
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
     } else {
      commit("setTableData", {
        tableHeaders: [
          "id",
          "education class name",
          "room",
          "mainview.class",
          "total students",
          "settings",
        ],
        tableData: response.data.classes,
        accessTableData: [
          ["id"],
          ["name"],
          ["room", "name"],
          ["grade", "name"],
          ["registration_record_count"],
        ],
        tableMeta: {
          total: null,
          page: null,
        },
      });
     }
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
      commit("setEduLoading", false);
    }
  },
  async fetchAllEduClasses({ commit, state }) {
    try {
      const eduClasses = await axios.get("/edu-class", {
        params: {
          size: 10000,
        },
      });
      commit("setEduClasses", eduClasses.data.classes);
      console.log("eduClasses", state.eduClasses);
    } catch (err) {}
  },
  async fetchEduClassInfo({ commit, state }, eduClassId) {
    state.gradeInfo = {};
    try {
      const eduClassInformation = await axios.get(`/edu-class/${eduClassId}`);
      commit("setEduClassInfo", eduClassInformation.data.data);
      console.log("eduClass info", eduClassInformation);
    } catch (err) {
      console.log("err", err);
    } finally {
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
