
import educationalYear from './educationalYear'
import terms from './terms'
import termType from './termType'
import gradeType from './gradeType'
import grade from './grade'
import floor from './floor'
import room from './room'
import educationClass from './educationClass'
import subjects from './subjects'
import gradeEducationClassShared from './grade-educationClass-shared/grade-EducationClass-shared'
import roles from './roles'

export default {
    terms,
    termType,
    grade,
    gradeType,
    educationalYear,
    floor,
    room,
    educationClass,
    subjects,
    gradeEducationClassShared,
    roles
}