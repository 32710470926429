import axios from 'axios'
const state = {
}
const getters = {

}
const mutations = {

}
const actions = {

}



export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}