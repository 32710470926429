<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" v-bind="attrs" v-on="on">mdi-delete</v-icon>
        </template>
        <v-card>
            <v-card-title class="text-h5" style="color: red;">
                {{ $t('are you sure you want delete') }}؟
            </v-card-title>

            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="dialog = false">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn color="blue" text @click="deleteMeal">
                <span v-if="!meal.deleteMealState.loading"> {{ $t('submit') }}</span>
                <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>
import { meal } from '../../../store/auth'
export default {
    props: ['item'],
    data() {
        return {
            meal,
            dialog: meal.deleteMealState.loading,
        }
    },
    methods: {
        deleteMeal() {
            meal.deleteMeal(this.item.id)
        }
    }
}
</script>