import axios from 'axios'
import i18n from '@/i18n';
import {confirmDialog} from "@/helpers/sweetalert";

const state = {
  options: {},
  serverTotal: 1,
  data: [
    {
      id: 39,
      date: "2023-05-31",
      note: "test",
      employee_name: "معاذ السوقي",
      contract_name: null,
      shift_name: null
    }
  ],
  url: '/hr/calculate/group',
  loading: false,
  headers: [
    {text: i18n.t('hr.time.date'), value: 'date'},
    {text: i18n.t('hr.employee.emp'), value: 'employee_name'},
    {text: i18n.t('hr.calculation.contract'), value: 'contract_name'},
    {text: i18n.t('hr.calculation.shift'), value: 'shift_name'},
    {text: i18n.t('hr.vacation.note'), value: 'note'}
  ],
  calculation: null,
  calculationHeaders: [
    {text: i18n.t('hr.time.emp-name'), value: 'employee'},
    {text: i18n.t('hr.calculation.contract'), value: 'contract_name'},
    {text: i18n.t('hr.employee.base_sal'), value: 'salary'},
    {text: i18n.t('hr.calculation.addons'), value: 'bonus_value'},
    {text: i18n.t('hr.calculation.discounts'), value: 'discount_value'},
    {text: i18n.t('hr.loans'), value: 'loans_value'},
    {text: i18n.t('hr.calculation.total'), value: 'final_salary'},
  ]
}
const getters = {
  data(state) {
    return state.data
  },
  isThereCalculation(state) {
    return state.calculation
  },
  calculationData(state) {
    return state.calculation?.calculate ?? []
  },
  headers(state) {
    return [...state.headers, {text: '', value: 'actions'}]
  },
  calculationHeaders(state) {
    return [...state.calculationHeaders]
  }
}
const mutations = {}
const actions = {
  async fetch({commit}, queryParams) {
    try {
      state.loading = true
      let params = {
        page: state.options?.page ?? 1,
        perPage: state.options?.itemsPerPage ?? 10,
        per_page: state.options?.itemsPerPage ?? 10,
      };
      const response = await axios.get(`${state.url}`, {
        params,
      });
      state.data = response.data.calculate_groups
      state.serverTotal = response.data.meta.total
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async generate({commit, dispatch}, form) {
    try {
      state.loading = true
      let params = {};
      const response = await axios.post(`${state.url}/start`, form);
      state.calculation = response.data.data
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  setCalculation({commit, dispatch}, form) {
    state.calculation = form
  },
  async sendMail({commit, dispatch}, id) {
    try {
      state.loading = true
      const response = await axios.get(`${state.url}/mail/${id}`);
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async delete({commit, state, dispatch}, id) {
    try {
      state.loading = true
      if (await confirmDialog()) {
        await axios.delete(`${state.url}/${id}`);
        dispatch('fetch')
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
