<template>
    <div>
        <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading" v-if="!driver.getDriverState.loading">
                    <tr>
                        <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                    </tr>
                </thead>
                <div class="table__spinnerWrapper" v-if="driver.getDriverState.loading">
                    <app-spinner></app-spinner>
                </div>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                    <tr v-for="(item, idx) in tableOptions.tableData" :key="idx"
                        v-show="!driver.getDriverState.loading">
                        <td>
                            <span>{{ item.id }}</span>
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ item.phone }}
                        </td>
                        <td>
                            {{ item.phone_2 }}
                        </td>
                        <td>
                            {{ item.user_name }}
                        </td>
                        <td v-if="!driver.getDriverState.loading" class="d-flex justify-center">
                            <edit-driver :item="item"></edit-driver>
                            <delete-driver :item="item"></delete-driver>
                        </td>
                    </tr>
                </v-fade-transition>
            </v-simple-table>
            <div class=" d-flex justify-end mt-5" v-if="!driver.getDriverState.loading">
                <app-pagination :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"
                    @PaginationValue="fetchNewPaginated($event)"></app-pagination>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import spinner from "./spinner.vue";
import pagination from "./pagination.vue";
import { driver } from '../../store/drivers'
import editDriver from '../../views/dashboard/drivers/editDriver.vue'
import deleteDriver from '../../views/dashboard/drivers/deleteDriver.vue'
export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
        editDriver,
        deleteDriver
    },
    data() {
        return {
            driver,
            deleteDialog: false,
            edit: false,
            item: null
        };
    },

    props: {
        serverRoute: String,
        enableDelete: {
            type: Boolean,
            default: true,
        },
        flaggedItems: {
            type: Array,
            default() {
                return [];
            },
        },
        displaySettings: {
            type: Boolean,
            default: true,
        },
        tableOptions: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    // computed: {
    //   ...mapGetters({
    //     tableOptions: "tablesStore/getTableOptions",
    //   }),
    // },
    methods: {
        castItems(item) {
            let copyItem = { ...item };
            this.flaggedItems.forEach((el) => {
                delete copyItem[el];
            });
            return copyItem;
        },
        accessData(row, index) {
            return this.fetchAttributes(
                row,
                this.tableOptions.accessTableData[index]
            );
        },
        fetchAttributes(row, nestedKey) {
            let value = row;
            for (let key in nestedKey) {
                if (value[nestedKey[key]]) {
                    value = value[nestedKey[key]];
                } else {
                    return;
                }
            }
            return value.toLocaleString();
        },
        fetchNewPaginated(paginationValue) {
            this.$emit("re-fetch-paginated-data", paginationValue);
        },
        reFetchData() {
            this.$emit("re-fetch-data");
        },

    },
};
</script>
  
<style >

</style>