import axios from 'axios'
import i18n from '@/i18n';

const state = {
  options: {},
  serverTotal: 1,
  page: 1,
  selectedEmployee: null,
  shifts: [
    {
      id: 1,
      name: 'test'
    }
  ],
  contracts: [
    {
      id: 1,
      name: 'test'
    }
  ],
  employeesContracts: [
    {
      id: 1,
      name: 'test'
    }
  ],
  employees: [
    {
      id: 1,
      name: 'test'
    }
  ],
  leaves: [
    {
      id: 1,
      name: 'test'
    }
  ],
  variations: [
    {
      id: 1,
      name: 'test'
    }
  ],
  loans: [
    {
      id: 1,
      name: 'test'
    }
  ],
  accounts: [
    {
      id: 1,
      name: 'test'
    }
  ],
  cashs: [
    {
      id: 1,
      name: 'test'
    }
  ],
  rules: [
    {
      id: 1,
      name: 'test'
    }
  ],
  custodies: [
    {
      id: 1,
      name: 'test'
    }
  ],
  payments: [
    {
      "id": 10,
      "entry_id": 5,
      "type": "credit",
      "amount": 1000,
      "cost_center": {
        "id": 6,
        "name": "asfa",
        "created_at": "2023-07-24T10:51:42.000000Z",
        "updated_at": "2023-07-24T10:51:42.000000Z"
      },
      "desc": "asfa \n",
      "account_id": 1,
      "entry": {
        "id": 5,
        "date": "2023-05-04",
        "desc": "توليد قيد للطالب testللتسجيل في الوجههasfa"
      },
      "date": null,
      "status": "test test"
    }
  ],
  data: [
    {
      "id": 2,
      "name": "emp",
      "middle_name": "mhmd",
      "last_name": "last",
      "mother_name": null,
      "address": "damasd",
      "nationlaty": "syr",
      "email": "moazalsouki99@gmail.com",
      "employment_date": "2023-01-25",
      "gender": "male",
      "passport_number": null,
      "note": "",
      "groups_count": 1,
      "groups": [
        {
          "id": 5,
          "name": "group name",
          "created_at": "2023-07-25T11:20:47.000000Z",
          "updated_at": "2023-07-25T11:20:47.000000Z",
          "pivot": {
            "employee_id": 2,
            "group_id": 5
          }
        }
      ],
      "account": {
        "id": 12,
        "name": "معاذ السوقي",
        "ownerable_id": 2,
        "ownerable_type": "Modules\\HR\\Entities\\Employee",
        "parent_id": 19,
        "serial": "1230001",
        "parent_family": [
          1,
          3,
          19
        ],
        "type": "balance",
        "cash": 0,
        "editable": 1,
        "hidden": 0,
        "last_updated_at": "2023-07-20 12:34:34",
        "created_at": "2023-07-20T12:34:34.000000Z",
        "updated_at": "2023-07-20T12:34:34.000000Z",
        "account_variants": []
      },
      "personal_rules": [
        {
          "id": 2,
          "changing_type": "decrease",
          "rule_type": "value",
          "name": "خصم معاذ",
          "value": 500
        }
      ],
      "contract_rules": [
        {
          "id": 1,
          "changing_type": "increase",
          "rule_type": "value",
          "name": "بدل مواصلات",
          "value": 1000
        }
      ],
      "code": null,
      "serial_number": 1,
      "iban": null,
      "brch_swift": null,
      "coin": null,
      "acount_number": null,
      "account_id": 1,
      "job": null,
      "zone": null,
      "location": null,
      "branch_name": null,
      "branch_number": null,
      "phone_number": null,
      "phone_number_2": null,
      "alternate_phone_number": null,
      "guarantee_number": null,
      "guarantee_salary": null,
      "guarantee_card": null,
      "personal_pic": null,
      "custodies": [
        {
          "id": 1,
          "custody_id": 2,
          "custody": {
            "id": 2,
            "name": "custody",
            "description": null,
            "created_at": null,
            "updated_at": null
          },
          "note": "add custody note"
        }
      ],
      "employee_contract": [
        {
          "id": 1,
          "status": "on_work",
          "salary": 30000,
          "start_at": "2023-05-01 00:00:00",
          "contract": "عقد عادي",
          "contract_id": 1,
          "leaves_in_year": 25,
          "shift": "وارديه",
          "shift_id": 1
        }
      ],
      "loan": [
        {
          "id": 9,
          "name": "سلفة",
          "value": 10000,
          "payments_number": 10,
          "payment": 1000,
          "given_date": "2023-05-01",
          "end_date": null,
          "stop_date": "2023-07-26",
          "loan_type_id": 1,
          "account": {
            "id": 1,
            "name": "account_1",
            "ownerable_id": null,
            "ownerable_type": null,
            "parent_id": 1,
            "serial": "1002",
            "parent_family": [
              1
            ],
            "type": "trading",
            "cash": 1,
            "editable": 1,
            "hidden": 0,
            "last_updated_at": "2023-07-29 16:06:49",
            "created_at": null,
            "updated_at": "2023-07-29T16:06:49.000000Z",
            "account_variants": [
              {
                "id": 2,
                "account_id": 1,
                "term_id": 1,
                "debit": 226270000,
                "credit": 29379011,
                "created_at": "2023-07-20T12:55:31.000000Z",
                "updated_at": "2023-07-29T16:06:49.000000Z"
              }
            ]
          },
          "account_id": 1,
          "employee_id": 2,
          "payments": 0
        },
        {
          "id": 13,
          "name": "سلفة",
          "value": 100000,
          "payments_number": 1,
          "payment": 100000,
          "given_date": "2023-05-01",
          "end_date": null,
          "stop_date": null,
          "loan_type_id": 1,
          "account": {
            "id": 1,
            "name": "account_1",
            "ownerable_id": null,
            "ownerable_type": null,
            "parent_id": 1,
            "serial": "1002",
            "parent_family": [
              1
            ],
            "type": "trading",
            "cash": 1,
            "editable": 1,
            "hidden": 0,
            "last_updated_at": "2023-07-29 16:06:49",
            "created_at": null,
            "updated_at": "2023-07-29T16:06:49.000000Z",
            "account_variants": [
              {
                "id": 2,
                "account_id": 1,
                "term_id": 1,
                "debit": 226270000,
                "credit": 29379011,
                "created_at": "2023-07-20T12:55:31.000000Z",
                "updated_at": "2023-07-29T16:06:49.000000Z"
              }
            ]
          },
          "account_id": 1,
          "employee_id": 2,
          "payments": 0
        }
      ],
      "contract_name": "عقد عادي",
      "shift_name": "وارديه"
    },
  ],
  url: '/hr/employee',
  employeesUrl: '/hr/employee/auto-complete',
  employeesContractsUrl: '/hr/employee/contract/auto-complete',
  shiftsUrl: '/hr/shift/auto-complete',
  contractsUrl: '/hr/contract/auto-complete',
  variationsUrl: '/hr/bonus/auto-complete',
  leavesUrl: '/hr/leave/type/auto-complete',
  loansUrl: '/hr/loan/type/auto-complete',
  accountsUrl: '/accounting/account/auto-complete',
  rulesUrl: '/hr/rule/auto-complete',
  accountStatementUrl: '/accounting/account/statement/',
  custodiesUrl: '/hr/rule/auto-complete',
  loading: false,
  headers: [
    {text: 'serial number', value: 'serial_number'},
    {text: 'hr.employee.first_name', value: 'name'},
    {text: 'hr.employee.last_name', value: 'last_name'},
    {text: 'hr.employee.contract', value: 'contract_name'},
    {text: 'hr.employee.shift', value: 'shift_name'},
    {text: 'hr.employee.email', value: 'email'},
    {text: 'hr.employee.emp_date', value: 'employment_date'},
  ],
  loanHeaders: [
    {text: i18n.t('hr.loan.type'), value: 'name'},
    {text: i18n.t('value'), value: 'value'},
    {text: i18n.t('hr.employee.number-of-payments'), value: 'payments_number'},
    {text: i18n.t('hr.loan.trans-value'), value: 'payment'},
    {text: i18n.t('hr.loan.loan-value'), value: 'account.name'},
    {text: i18n.t('hr.time.date'), value: 'given_date'},
    {text: i18n.t('hr.vacation.note'), value: 'note'},
    {text: i18n.t('hr.employee.transactions-payed'), value: 'payments'},
    {text: i18n.t('hr.employee.date-of-payment'), value: 'stop_date'},
  ],
  checkHeaders: [
    {text: i18n.t('hr.employee.check.loan-taker'), value: 'debit'},
    {text: i18n.t('hr.employee.check.loan-owner'), value: 'credit'},
    {text: i18n.t('hr.employee.check.account'), value: 'status'},
    {text: i18n.t('hr.employee.check.description'), value: 'desc'},
    {text: i18n.t('hr.employee.check.cost-center'), value: 'cost_center.name'},
    {text: i18n.t('hr.employee.check.date'), value: 'date'},

  ]
}

const getters = {
  payments(state) {
    return state.payments.map(payment => ({...payment, debit: payment.type === 'debit' ? payment.amount : '', credit: payment.type === 'credit' ? payment.amount : ''}))
  },
  data(state) {
    return state.data
  },
  shifts(state) {
    return state.shifts
  },
  contracts(state) {
    return state.contracts
  },
  employees(state) {
    return state.employees
  },
  employeesContracts(state) {
    return state.employeesContracts
  },
  leaves(state) {
    return state.leaves
  },
  variations(state) {
    return state.variations
  },
  loans(state) {
    return state.loans
  },
  accounts(state) {
    return state.accounts
  },
  cashs(state) {
    return state.cashs
  },
  rules(state) {
    return state.rules
  },
  custodies(state) {
    return state.custodies
  },
  headers(state) {
    return [...state.headers, {text: 'settings', value: 'actions'}]
  },
  loanHeaders(state) {
    return [...state.loanHeaders]
  },
  checkHeaders(state) {
    return [...state.checkHeaders]
  }
}
const mutations = {}
const actions = {
  setEmployee({}, employee) {
    state.selectedEmployee = employee
  },
  async fetch({commit}, queryParams) {
    try {
      state.loading = true
      let params = {
        page: state.options?.page ?? 1,
        perPage: state.options?.itemsPerPage ?? 10,
        per_page: state.options?.itemsPerPage ?? 10,
        ...queryParams,
      };
      const response = await axios.get(`${state.url}`, {
        params,
      });
      state.data = response.data.data
      state.serverTotal = response.data.meta.total
      state.page = response.data.meta.current_page
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async fetchEmployee({commit}, queryParams) {
    try {
      state.loading = true
      
      const response = await axios.get(`${state.url}/${queryParams}`);
      let da = response.data.data.data
      return da;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  
  async update({commit, dispatch}, form) {
    try {   
      console.log(form);
      console.log("object");
      state.loading = true
      const response = await axios.put(`${state.url}/${form.id}`, form);
      if(response.status==200){
        return response;
      }
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async fetchShifts({commit}, queryParams = '') {
    state.shifts = (await axios.get(`${state.shiftsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchContracts({commit}, queryParams = '') {
    state.contracts = (await axios.get(`${state.contractsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchEmployees({commit}, queryParams = '') {
    state.employees = (await axios.get(`${state.employeesUrl}`, {
        params: {
          name: queryParams
        },
      })).data.data;
  },
  async fetchEmployeesContracts({commit}, queryParams = '') {
    state.employeesContracts = (await axios.get(`${state.employeesContractsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchLeaves({commit}, queryParams = '') {
    state.leaves = (await axios.get(`${state.leavesUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
    console.log(state.leaves);
  },
  async fetchVariations({commit}, queryParams = '') {
    state.variations = (await axios.get(`${state.variationsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
    console.log(state.variations);
  },
  async fetchLoans({commit}, queryParams = '') {
    state.loans = (await axios.get(`${state.loansUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchAccounts({commit}, queryParams = '') {
    state.accounts = (await axios.get(`${state.accountsUrl}`, {
      params: {
        query: queryParams
      },
    })).data.data;
    return state.accounts
  },
  async fetchCashs({commit}, queryParams = '') {
    if(queryParams != '')
   {
    state.cashs = (await axios.get(`${state.accountsUrl}`, {
      params: {
        query: queryParams,
        cash: 1,
      },
    })).data.data;
   }
  },
  async fetchAccountStatement({commit}, id) {
    state.payments = (await axios.get(`${state.accountStatementUrl}/${id}`)).data.data;
  },
  async fetchRules({commit}, queryParams = '') {
    state.cashs = (await axios.get(`${state.rulesUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchCustodies({commit}, queryParams = '') {
    state.cashs = (await axios.get(`${state.custodiesUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async store({commit, dispatch}, data) {
    // loading
    try {
      state.loading = true
      // let formemployee = da;
      // for (let key in data) {
      //     if (data[key]) {
      //         formemployee.append(`${key}`, data[key])
      //     }
      // }
      // let formemployee_contract = new FormData();
      // for (let key in data['employee_contract']) {
      //     if (data['employee_contract'][key]) {
      //       formemployee_contract.append(`${key}`, data['employee_contract'][key])
      //     }
      // }
      // let form = new FormData();
      // form.append("employee",formemployee);
      // form.append("employee_contract",formemployee_contract)
      const response = await axios.post(`${state.url}`, data);
      
      if(response.status==200){
        return response;
      }
      dispatch('fetch')
     
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }    // call then close the loading
  },
  async deleteEmployeeContract({commit, dispatch}, id) {
    // loading
      const response = await axios.delete(`${state.url}/contract/${id}`);
      dispatch('fetch')
  },
  async saveEmployeeContract({commit, dispatch}, data) {
    // loading
      const response = await axios.post(`${state.url}/contract`, data);

      dispatch('fetch')
  },
  async deleteEmployee({commit, dispatch}, id) {
    // loading
      const response = await axios.delete(`${state.url}/${id}`);
      dispatch('fetch')
  },
  async deleteEmployeeRule({commit, dispatch}, data) {
    // loading
      const response = await axios.delete(`${state.url}/contract/rule`, {
        params: {
          ...data
        }
      });
      dispatch('fetch')
  },
  async saveEmployeeRule({commit, dispatch}, data) {
    // loading
    const response = await axios.post(`${state.url}/contract/rule`, data);
    dispatch('fetch')
    return response;
  },
  async deleteEmployeeCustody({commit, dispatch}, id) {
    // loading
    const response = await axios.delete(`${state.url}/custody/${id}`);
    dispatch('fetchCustodies')
  },
  async saveEmployeeCustody({commit, dispatch}, data) {
    // loading
    const response = await axios.post(`${state.url}/custody`, data);
    dispatch('fetchCustodies')
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
