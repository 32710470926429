import axios from 'axios'
const state = {
    floors: [],
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
}
const getters = {
    getFloors(state) {
        return state.floors
    },
    getTableOptions(state) {
        return state.tableOptions
    },

}
const mutations = {
    setFloors(state, floors) {
        state.floors = floors
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
}
const actions = {
    async fetchFloors({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
                name: queryParams.nameSearch,
            };
            if (queryParams.paginationValue) {
                params.page = queryParams.paginationValue;
            }
            if (!queryParams.nameSearch) {
                delete params.name;
            }
            const response = await axios.get("/floor", {
                params,
            });
            commit('setTableData', {
                tableHeaders: ["id", "name", "arrangement", "settings"],
                tableData: response.data.floors,
                accessTableData: [["id"], ["name"], ["order"]],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchAllFloors({ commit }) {
        try {
            const floors = await axios.get("/floor", {
                params: {
                    size: 100000,
                },
            });
            commit('setFloors', floors.data.floors)
        }
        catch (err) { }
    },

}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}