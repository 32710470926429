<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" v-bind="attrs" v-on="on">mdi-delete</v-icon>
        </template>
        <v-card>
            <v-card-title class="text-h5" style="color: red;">
                {{ $t('are you sure you want delete') }}؟
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="dialog = false">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn color="blue" text @click="deletestudent">
                <span v-if="!driverRoute.deleteRouteState.loading"> {{ $t('submit') }}</span>
                <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { driverRoute } from '../../../store/driverRoutes'
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {};
            },
        }
    },
    data() {
        return {
            driverRoute,
            dialog: driverRoute.deleteRouteState.loading,
            r_id: driverRoute.routeInfoState.value.data.id,
        }
    },
    methods: {
       async deletestudent() {
           let res = await axios.delete(`/student_route/${this.item.id}`);
           if(res.status==200){
            this.$Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
           } 
           this. driverRoute.getStudentsInRoute({route_id : this.r_id});
           //this.$router.go(-1);
           this.dialog=false;
        }
    }
}
</script>