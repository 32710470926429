<template>
  <div>
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      // currentRoutePath: null,
    };
  },
  // computed: {
  //   currentRoutePath() {
  //     return this.$route.path;
  //   }
  // },
  // watch: {
  //   "$route.path" : function() {
  //     console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");
  //     console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");

  //   console.log(this.$route.path);
  //   console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");
  //   console.log("lkjgkhjghjfhjfklhjkgjhkfgjhkfgjkgjhfgjhkfl");
  // } 
  // },

  created() {
    // console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");
    // console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");
    // console.log(this.$route.path);
    // console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");
    // console.log("dfsdfsdfsdfsdfsdfsdfsddfsdffsddfsdf");
    // this.$router.beforeEach((to, from, next) => {
    //   if (to.path !== '/create-temp-student-register') {
    //     if (!localStorage.getItem("access_token")) {
    //       this.$router.replace("/Login");
    //     }
    //     else {
    //       this.$store.dispatch("autoLogIn");
    //     }
    //   } else {
    //     this.$router.replace("/create-temp-student-register");
    //   }

    // });
  //   if(this.$route.path !== "/create-temp-student-register") {

  //       if (!localStorage.getItem("access_token")) {
  //       this.$router.replace("/Login");
  //     } else {
  //       this.$store.dispatch("autoLogIn");
  //     }
  //   }
  },
};
</script>

<style src="./globalCss/global.css"></style>
