<template>
  <v-dialog v-model="deleteDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" style="color: var(--del-color-icon) !important">mdi-delete</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2 v-if="tableRecord.name">
          {{ $t("are you sure delete") }}{{ tableRecord.name }} ؟
        </h2>
        <h2 v-else>{{ $t("are you sure delete") }}{{ tableRecord.id }}؟</h2>
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          depressed
          @click="deleteRecord"
          :loading="deleteBtnLoading"
          >{{ $t("submit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Notifications from "../../helpers/toast-notifications";
export default {
  data() {
    return {
      deleteBtnLoading: false,
      deleteDialog: false,
    };
  },
  props: {
    tableRecord: Object,
    serverRoute: String,
  },
  methods: {
    async deleteRecord() {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`${this.serverRoute}/${this.tableRecord.id}`);
        this.$emit("record-deleted");
        this.deleteDialog = false;
        Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
  },
};
</script>

<style></style>
