import axios from "axios";

const state = {};
const getters = {};
const mutations = {};
const actions = {
  updateStudent({ commit }, studentData) {
    return new Promise(async (resolve, reject) => {
      try {
        let form = new FormData();
        form.append("_method", "put");
        form.append("first_name", studentData.student.first_name);
        form.append("last_name", studentData.student.last_name);
        form.append("father_name", studentData.student.father_name);
        form.append("mother_name", studentData.student.mother_name);
        form.append("gender", studentData.student.gender);
        form.append("type", studentData.student.type);
        form.append("grand_father_name", studentData.student.grand_father_name);
        form.append("birthday", studentData.student.birthday);
        form.append("transfer_document_date", studentData.student.transfer_document_date);
        form.append("join_date", studentData.student.join_date);
        form.append("birth_location", studentData.student.birth_location);
        form.append("father_job", studentData.student.father_job);
        form.append("phone_number", studentData.student.phone_number);
        form.append("mobile_number", studentData.student.mobile_number);
        form.append("foreign_language", studentData.student.foreign_language);
        form.append("nationality", studentData.student.nationality);
        form.append("student_email", studentData.student.student_email);
        form.append("parent_email", studentData.student.parent_email);
        form.append(
          "parent_passport_number",
          studentData.student.parent_passport_number
        );
        form.append(
          "student_passport_number",
          studentData.student.student_passport_number
        );
        form.append("address", studentData.student.address);
        form.append("student_contact", studentData.student.student_contact);
        form.append(
          "public_record_number",
          studentData.student.public_record_number
        );
        form.append(
          "buffet_note",
          studentData.student.buffet_note
        );
        form.append(
          "number_transfer_out_document",
          studentData.student.number_transfer_out_document
        );
        //
        if (studentData.student.type == "transported") {
          if (typeof studentData.student.transfer_document_photo != "string") {
            form.append(
              "transfer_document_photo",
              studentData.student.transfer_document_photo
            );
          }
          form.append(
            "number_transfer_document",
            studentData.student.number_transfer_document
          );
        }
        if (
          typeof studentData.student.family_book_1 != "string" &&
          studentData.student.family_book_1 != null
        ) {
          form.append("family_book_1", studentData.student.family_book_1);
        }
        if (
          typeof studentData.student.family_book_2 != "string" &&
          studentData.student.family_book_2 != null
        ) {
          form.append("family_book_2", studentData.student.family_book_2);
        }
        if (
          typeof studentData.student.family_book_3 != "string" &&
          studentData.student.family_book_3 != null
        ) {
          form.append("family_book_3", studentData.student.family_book_3);
        }
        if (
          typeof studentData.student.family_book_4 != "string" &&
          studentData.student.family_book_4 != null
        ) {
          form.append("family_book_4", studentData.student.family_book_4);
        }
        if (
          typeof studentData.student.father_photo_id != "string" &&
          studentData.student.father_photo_id != null
        ) {
          form.append("father_photo_id", studentData.student.father_photo_id);
        }
        if (
          typeof studentData.student.vaccine_card != "string" &&
          studentData.student.vaccine_card != null
        ) {
          form.append("vaccine_card", studentData.student.vaccine_card);
        }
        if (
          typeof studentData.student.medical_report != "string" &&
          studentData.student.medical_report != null
        ) {
          form.append("medical_report", studentData.student.medical_report);
        }
        if (
          typeof studentData.student.student_photo != "string" &&
          studentData.student.student_photo != null
        ) {
          form.append("student_photo", studentData.student.student_photo);
        }

        if (
          typeof studentData.student.study_sequence != "string" &&
          studentData.student.study_sequence != null
        ) {
          form.append("study_sequence", studentData.student.study_sequence);
        }
        await axios.post(`/student/${studentData.studentId}`, form);
        resolve();
      } catch (err) {
        console.log("err", err);
        reject();
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
