import axios from "axios";
const state = {
  educationalYears: [],
  tableOptions: {
    tableHeaders: [],
    tableData: [],
    accessTableData: [],
    loading: false,
    tableMeta: {
      total: 10,
      page: 1,
    },
  }
};
const getters = {
  getTableOptions(state) {
    return state.tableOptions
  },
};
const mutations = {
  setLoading(state, status) {
    state.tableOptions.loading = status
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders
    state.tableOptions.tableData = tableInfo.tableData
    state.tableOptions.accessTableData = tableInfo.accessTableData
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
  }
};
const actions = {
  async fetchCostCenter({ commit }, queryParams) {
    try {
      commit('setLoading', true)
      let params = {
        size: 10
      };
      for (let key in queryParams) {
        if (queryParams[key]) {
          params[key] = queryParams[key];
        }
      }
      const response = await axios.get("/accounting/cost-center", {
        params
      });
      commit(
        "setTableData",
        {
          tableHeaders: ["id", "name", "settings"],
          tableData: response.data.data,
          accessTableData: [["id"], ["name"]],
          tableMeta: {
            total: response.data.meta.total,
            page: response.data.meta.current_page
          }
        },
      );
    } catch (err) {
      console.log("err", err);
    } finally {
      commit('setLoading', false)
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
