import teachers from "./teachers";
import responsibilities from "./responsibilities";
import lessons from "./lessons";
import homeworks from "./homeworks";
import attachments from "./attachments"


export default {
  teachers,
  attachments,
  responsibilities,
  lessons,
  homeworks,
};
