import axios from "axios";

const getDefaultState = () => {
  return {
    loading: false,
    homeworks: [],
    attachments:[],
    oneHomework:{},
    homework: {
      title: {
        desc:"title",
        value:""
      },
      description: {
        desc:"description",
        value:""
      },
      due_to: {
        desc:"due to",
        value:""
      },
      
      created_at: {
        desc:"created at",
        value:""
      },
      homework_title: {
        desc:"homework title",
        value:""
      },
      teacher: {
        desc:"teacher",
        value:""
      },
      subject: {
        desc:"subject",
        value:""
      },
      edu_class: {
        desc:"educitional class",
        value:""
      },
      class: {
        desc:"class",
        value:""
      },
    },
    tableOptions: {
      tableHeaders: [],
      tableData: [],
      accessTableData: [],
      loading: false,
      tableMeta: {
        total: 10,
        page: 1,
      },
    },
  };
};
const state = getDefaultState();

const getters = {
  getTableOptions(state) {
    return state.tableOptions;
  },
getOneHomework(state){
  return state.oneHomework;
},
  getHomeWorks(state) {
    return state.homeworks;
  },
  getHomeworkData() {
    return state.homework;
  },
  getLoadingStatus(state) {
    return state.loading;
  },

  getTableLoading(state) {
    return state.tableOptions.loading;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setHomeworks(state, homeworkInfo) {
    state.homeworks = homeworkInfo;
  },
  setOneHomework(state, homeworkInfo) {
    state.oneHomework = homeworkInfo;
  },
  setHomeworkInformation(state, homeworkInfo) {
    state.homework.attachments = homeworkInfo.attachments;
    state.homework.title.value = homeworkInfo.title;
    state.homework.description.value = homeworkInfo.description;
    state.homework.due_to.value = homeworkInfo.due_to;
    state.homework.homework_title.value = homeworkInfo.lesson.title;
    state.homework.teacher.value = homeworkInfo.lesson.responsibility.teacher.name;
    state.homework.subject.value = homeworkInfo.lesson.responsibility.subject.name;
    state.homework.created_at.value = homeworkInfo.created_at;
    state.homework.class.value = homeworkInfo.lesson.responsibility.grade.name;
    state.homework.edu_class.value = homeworkInfo.lesson.responsibility.edu_class.name;
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },

  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchHomeworks({ commit }, payload) {
    try {
      console.log("fetchHomeworks");
      commit("setLoading", true);
      let params = {
        size: 10,
      };
      for (let key in payload.params) {
        if (payload.params[key]) {
          params[key] = payload.params[key];
        }
      }
      const response = await axios.get("/homework", {
        params,
      });
      console.log("faam", response.data.homeworks);
      commit("setHomeworks", response.data.homeworks);
      let tableHeaders = [];
      let accessTableData = [];
      tableHeaders = [
        "id",
        "homework title",
        "description",
        "due to",
        "lesson title",
        "teacher",
        "subject",
        "settings",
      ];
      accessTableData = [
        ["id"],
        ["title"],
        ["description"],
        ["due_to"],
        ["lesson", "title"],
        ["lesson", "responsibility", "teacher", "name"],
        ["lesson", "responsibility", "subject", "name"],
      ];

      commit("setTableData", {
        tableHeaders,
        tableData: response.data.homeworks,
        accessTableData,
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchHomework({ commit, state }, homeworkId) {
    try {
      commit("resetState");
      commit("setLoadingStatus", true);
      const response = await axios.get(`/homework/${homeworkId}`);
      console.log("reg record faaam homework", response);
      let responseData = response.data.data;

      commit("setHomeworkInformation", responseData);
      commit("setOneHomework",responseData)
    } catch (err) {
      console.log("errrr", err);
    } finally {
      commit("setLoadingStatus", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
