<template>
  <div >
   
    
    
    <v-pagination :disabled="disablePagination" v-model="paginationPage" :length="totalPagesToDisplay"
      :total-visible="6" circle light next-icon="mdi-chevron-double-right" prev-icon="mdi-chevron-double-left"
      @input="updateValue"></v-pagination> 
      
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      paginationPage: this.page,
      paginationTotalPages: this.totalPages,
      paginationperPage: 10
    };
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    perPage: Number,
    totalPages: Number,
    disablePagination: Boolean,
  },
  methods: {
    updateValue(value) {
      this.$emit("PaginationValue", value);
    },
  },
  computed: {
    totalPagesToDisplay() {
      if (this.paginationTotalPages > 0) {
        return Math.ceil(this.paginationTotalPages / this.paginationperPage);
      }
      return 1;
    },
  },
  mounted() {
    if (this.perPage) {
      this.paginationperPage = this.perPage
    }
  }
};
</script>

<style>

</style>