import axios from 'axios'
const state = {
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
}
const getters = {
    getTableOptions(state) {
        return state.tableOptions
    },
}
const mutations = {
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
}
const actions = {
    async fetchTests({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
            };
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key]
                }
            }
            const response = await axios.get("/test", {
                params,
            });

            commit('setTableData', {
                tableHeaders: ["id","date", "education class name", "subject name", "Partition Title","Test Name", "Percent", "maximum mark","status",  "settings"],
                tableData: response.data.tests,
                accessTableData: [["id"],["date"], ["edu_class","name"], ["subject", "name"], ['subject_partition',"name"], ["name"], ["percent"], ["max_mark"],['status'] ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
}
