import axios from 'axios'
const state = {
    rooms: [],
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
}
const getters = {
    getRooms(state) {
        return state.rooms
    },
    getTableOptions(state) {
        return state.tableOptions
    },
}
const mutations = {
    setRooms(state, rooms) {
        state.rooms = rooms
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
}
const actions = {
    async fetchRooms({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
                name: queryParams.nameSearch,
                floor_id: queryParams.floorId
            };
            if (queryParams.paginationValue) {
                params.page = queryParams.paginationValue;
            }
            if (!queryParams.nameSearch) {
                delete params.name;
            }
            if (!queryParams.floorId) {
                delete params.floor_id;
            }
            const response = await axios.get("/room", {
                params,
            });
            commit('setTableData', {
                tableHeaders: ["id", "name", "floor", "settings"],
                tableData: response.data.rooms,
                accessTableData: [["id"], ["name"], ["floor", "name"]],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchAllRooms({ commit }) {
        try {
            const rooms = await axios.get("/room", {
                params: {
                    size: 1000,
                },
            });
            commit('setRooms', rooms.data.rooms)
        }
        catch (err) { }
    }
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}