import axios from "axios";

const state = {
  gradeTypes: [],
  gradeTypeLoading: false,
  tableOptions: {
    tableHeaders: [],
    tableData: [],
    accessTableData: [],
    loading: false,
    tableMeta: {
      total: 10,
      page: 1,
    },
  },
};
const getters = {
  getGradeTypes(state) {
    return state.gradeTypes;
  },
  getGradeTypeLoading(state) {
    return state.gradeTypeLoading;
  },
  getTableOptions(state) {
    return state.tableOptions;
  },
};
const mutations = {
  setGradeTypes(state, gradeTypes) {
    state.gradeTypes = gradeTypes;
  },
  setGradeTypeLoading(state, status) {
    state.gradeTypeLoading = status;
  },
  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchGradeTypes({ commit }, queryParams) {
    try {
      commit("setLoading", true);
      let params = {
        size: 10,
        name: queryParams.nameSearch,
        page: queryParams.page,
      };
      if (queryParams.paginationValue) {
        params.page = queryParams.page;
      }
      if (!queryParams.nameSearch) {
        delete params.name;
      }

      const response = await axios.get("/grade-type", {
        params,
      });
      commit("setTableData", {
        tableHeaders: ["id", "name", "settings"],
        tableData: response.data.grade_types,
        accessTableData: [["id"], ["name"]],
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAllGradeTypes({ commit }) {
    try {
      commit("setGradeTypeLoading", true);
      const gradeTypes = await axios.get("/grade-type", {
        params: {
          size: 100000,
        },
      });
      commit("setGradeTypes", gradeTypes.data.grade_types);
    } catch (err) {
    } finally {
      commit("setGradeTypeLoading", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
