import student from './student'
import registrationRecord from './registrationRecord'
import createStudent from './createStudent'
import editStudent from './editStudent'
import buffet from './buffet'
export default {
    buffet,
    student,
    registrationRecord,
    createStudent,
    editStudent,
}