import axios from "axios";
const state = {
  tableOptions: {
    tableHeaders: [],
    tableData: [],
    accessTableData: [],
    loading: false,
    tableMeta: {
      total: 10,
      page: 1,
    },
  },
};
const getters = {
  getTableOptions(state) {
    return state.tableOptions;
  },
};

const mutations = {
  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};

const actions = {
  async fetchStudents({ commit }, queryParams) {
    try {
      commit("setLoading", true);
      let params = {};
      if(!queryParams.paginated){
         params = {
          paginated: queryParams.paginated,
          archive: queryParams.archive,
          grade: queryParams.grade,
          id: queryParams.id,
          edu_class: queryParams.edu_class,
          name: queryParams.name,
          gender: queryParams.gender,
          last_name: queryParams.last_name,
          father_name: queryParams.father_name,
          mother_name: queryParams.mother_name,
          public_record_number: queryParams.public_record_number,
          phone_number: queryParams.phone_number,
          type: queryParams.type,
          buffet_card: queryParams.buffet_card,
        };
      } else {
        params = {
          size: queryParams.size,
          paginated: queryParams.paginated,
          archive: queryParams.archive,
          grade: queryParams.grade,
          id: queryParams.id,
          edu_class: queryParams.edu_class,
          name: queryParams.name,
          gender: queryParams.gender,
          last_name: queryParams.last_name,
          father_name: queryParams.father_name,
          mother_name: queryParams.mother_name,
          public_record_number: queryParams.public_record_number,
          phone_number: queryParams.phone_number,
          type: queryParams.type,
          buffet_card: queryParams.buffet_card,
        };
        if (queryParams.paginationValue) {
          params.page = queryParams.paginationValue;
        }
      }
      if(queryParams.card_view) {
        params.card_view = queryParams.card_view;
      }
      
      if (!queryParams.name) {
        delete params.name;
      }
      if (queryParams.type == "both") {
        delete params.type;
      }
      if (queryParams.gender == "both") {
        delete params.gender;
      }
      if (!queryParams.buffet_card) {
        delete params.buffet_card;
      }

      if (!queryParams.record_type || queryParams.record_type == "permanent") {
        params.record_type = "permanent";
      } else {
        params.record_type = "temp";
      }

      const response = await axios.get("/student", {
        params,
      });
      
      response.data.students.forEach((el) => {
        if (el.gender == "male") {
          el.gender = "male";
        } else {
          el.gender = "female";
        }
        if (el.type == "new") {
          el.type = "new";
        } else {
          el.type = "Transported";
        }
      });

      const tableHeaders = [
        "name",
        "mother name",
        "educitional class",
        "gender",
        "type",
        "settings",
      ];
      const accessTableData = [
        ["full_name"],
        ["mother_name"],
        ["edu_class"],
        ["gender"],
        ["type"],
      ];

      if (!queryParams.record_type || queryParams.record_type !== "temp") {
       
        tableHeaders.splice(0, 0, "public record number");
        accessTableData.splice(0, 0, ["public_record_number"]);
      }

      if(!queryParams.paginated){
        commit("setTableData", {
          tableHeaders: tableHeaders,
          tableData: response.data.students,
          accessTableData: accessTableData,
          tableMeta: {
            total: null,
            page: null,
          },
        });
      } else {
        commit("setTableData", {
          tableHeaders: tableHeaders,
          tableData: response.data.students,
          accessTableData: accessTableData,
          tableMeta: {
            total: response.data.meta.total,
            page: response.data.meta.current_page,
          },
        });
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchStudentsSibling({ commit }, queryParams) {
    try {
      commit("setLoading", true);
      let params = {};
      if(!queryParams.paginated){
         params = {
          paginated: queryParams.paginated,
          archive: queryParams.archive,
          grade: queryParams.grade,
          id: queryParams.id,
          edu_class: queryParams.edu_class,
          name: queryParams.name,
          gender: queryParams.gender,
          last_name: queryParams.last_name,
          father_name: queryParams.father_name,
          mother_name: queryParams.mother_name,
          public_record_number: queryParams.public_record_number,
          phone_number: queryParams.phone_number,
          type: queryParams.type,
          buffet_card: queryParams.buffet_card,
        };
      } else {
        params = {
          size: queryParams.size,
          paginated: queryParams.paginated,
          archive: queryParams.archive,
          grade: queryParams.grade,
          id: queryParams.id,
          edu_class: queryParams.edu_class,
          name: queryParams.name,
          gender: queryParams.gender,
          last_name: queryParams.last_name,
          father_name: queryParams.father_name,
          mother_name: queryParams.mother_name,
          public_record_number: queryParams.public_record_number,
          phone_number: queryParams.phone_number,
          type: queryParams.type,
          buffet_card: queryParams.buffet_card,
        };
        if (queryParams.paginationValue) {
          params.page = queryParams.paginationValue;
        }
      }

      
      if (!queryParams.name) {
        delete params.name;
      }
      if (queryParams.type == "both") {
        delete params.type;
      }
      if (queryParams.gender == "both") {
        delete params.gender;
      }
      if (!queryParams.buffet_card) {
        delete params.buffet_card;
      }

      if (!queryParams.record_type || queryParams.record_type == "permanent") {
        params.record_type = "permanent";
      } else {
        params.record_type = "temp";
      }

      const response = await axios.get(`/student/${queryParams.sibling_id}/siblings`, {
        params,
      });
      
      response.data.data.forEach((el) => {
        if (el.gender == "male") {
          el.gender = "male";
        } else {
          el.gender = "female";
        }
        if (el.type == "new") {
          el.type = "new";
        } else {
          el.type = "Transported";
        }
      });

      const tableHeaders = [
        "name",
        "mother name",
        "educitional class",
        "gender",
        "type",
        "settings",
      ];
      const accessTableData = [
        ["full_name"],
        ["mother_name"],
        ["edu_class"],
        ["gender"],
        ["type"],
      ];

      if (!queryParams.record_type || queryParams.record_type !== "temp") {
       
        tableHeaders.splice(0, 0, "public record number");
        accessTableData.splice(0, 0, ["public_record_number"]);
      }

      if(!queryParams.paginated){
        commit("setTableData", {
          tableHeaders: tableHeaders,
          tableData: response.data.data,
          accessTableData: accessTableData,
          tableMeta: {
            total: null,
            page: null,
          },
        });
      } else {
        commit("setTableData", {
          tableHeaders: tableHeaders,
          tableData: response.data.data,
          accessTableData: accessTableData,
          tableMeta: {
            total: response.data.meta.total,
            page: response.data.meta.current_page,
          },
        });
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
