<template>
    <div>
        <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading" v-if="!driverRoute.tripsState.loading">
                    <tr>
                        <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                    </tr>
                </thead>
                <div class="table__spinnerWrapper" v-if="driverRoute.tripsState.loading">
                    <app-spinner></app-spinner>
                </div>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                    <tr v-for="(item, idx) in tableOptions.tableData" :key="idx"
                        v-show="!driverRoute.tripsState.loading">
                        <td>
                            <span>{{ item.id }}</span>
                        </td>
                        <td>
                            {{ item.status }}
                        </td>
                        <td>
                            {{ getServerDate(item.created_at) }}
                        </td>
                        <td v-if="displaySettings">

                            <delete-route :item="item"></delete-route>
                            <edit-route :item="item"></edit-route>

                        </td>

                    </tr>
                </v-fade-transition>
            </v-simple-table>
            <div class=" d-flex justify-end mt-5" v-if="!driverRoute.tripsState.loading">
                <app-pagination :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"
                    :perPage="getPerPage()" @PaginationValue="fetchNewPaginated($event)"></app-pagination>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import spinner from "./spinner.vue";
import pagination from "./pagination.vue";
import { driverRoute } from '../../store/driverRoutes'
import deleteRoute from '../../views/dashboard/busRoute/deleteRoute.vue'
import editRoute from '../../views/dashboard/busRoute/editRoute.vue'
export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
        deleteRoute,
        editRoute
    },
    data() {
        return {
            driverRoute,
            deleteDialog: false,
            edit: false,
            item: null
        };
    },

    props: {
        serverRoute: String,
        enableDelete: {
            type: Boolean,
            default: true,
        },
        flaggedItems: {
            type: Array,
            default() {
                return [];
            },
        },
        displaySettings: {
            type: Boolean,
            default: true,
        },
        tableOptions: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    // computed: {
    //   ...mapGetters({
    //     tableOptions: "tablesStore/getTableOptions",
    //   }),
    // },
    methods: {
        getPerPage() {
            if (this.tableOptions.tableMeta.perPage) {
                return this.tableOptions.tableMeta.perPage
            } else {
                return 10
            }
        },
        getServerDate(date) {
            return date.slice(0, 10)

        },
        castItems(item) {
            let copyItem = { ...item };
            this.flaggedItems.forEach((el) => {
                delete copyItem[el];
            });
            return copyItem;
        },
        accessData(row, index) {
            return this.fetchAttributes(
                row,
                this.tableOptions.accessTableData[index]
            );
        },
        fetchAttributes(row, nestedKey) {
            let value = row;
            for (let key in nestedKey) {
                if (value[nestedKey[key]]) {
                    value = value[nestedKey[key]];
                } else {
                    return;
                }
            }
            return value.toLocaleString();
        },
        fetchNewPaginated(paginationValue) {
            this.$emit("re-fetch-paginated-data", paginationValue);
        },
        reFetchData() {
            this.$emit("re-fetch-data");
        },
    },
};
</script>
  
<style >

</style>