const state = {
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
}
const mutations = {
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
    setTableLoadingStatus(state, loadingStatus) {
        state.tableOptions.loading = loadingStatus
    }
}
const actions = {

}
const getters = {
    getTableOptions(state) {
        return state.tableOptions
    },
    getTableLoading(state) {
        return state.tableOptions.loading
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}