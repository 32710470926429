import axios from 'axios'

const state = {
    cardId: null,
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    },

}
const getters = {
    getCardId(state) {
        return state.cardId
    },
    getTableOptions(state) {
        return state.tableOptions
    },
}
const mutations = {
    setCardId(state, id) {
        state.cardId = id
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
}
const actions = {
    async fetchDepositCardRecord({ commit, state }, queryParams) {
        try {
            commit('setLoading', true)
            commit('setTableData', {
                tableHeaders: [],
                tableData: [],
                accessTableData: [],
                tableMeta: {
                    total: 1,
                    page: 1,
                },
            })
            let params = {
                size: 10,
            };
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key]
                }
            }
            const response = await axios.get(`/deposit-card-record/${state.cardId}`, {
                params,
            });
            response.data.data.forEach(el => {
                if (el.type == 'charge') {
                    delete el.type
                    el.type = 'شحن'
                } else {
                    delete el.type
                    el.type = 'سحب'
                }
            })
            commit('setTableData', {
                tableHeaders: [
                    "Id",
                    "الكمية",
                    "النوع",
                    "التاريخ",
                    "اسم المستخدم",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["id"],
                    ["amount"],
                    ["type"],
                    ["date"],
                    ["username"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchPurchaseRecord({ commit, state }, queryParams) {
        try {
            commit('setLoading', true)
            commit('setTableData', {
                tableHeaders: [],
                tableData: [],
                accessTableData: [],
                tableMeta: {
                    total: 1,
                    page: 1,
                },
            })
            let params = {
                size: 10,
            };
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key]
                }
            }
            const response = await axios.get('/purchase-history', {
                params,
            });
            console.log('deposit-card-record', response.data.data);
            response.data.data.forEach(el => {
                if (el.type == 'return') {
                    delete el.type
                    el.type = 'مرتجع'
                } else {
                    delete el.type
                    el.type = 'شراء'
                }
            })
          
            commit('setTableData', {
                tableHeaders: [
                    "Id",
                    "اسم المادة",
                    "الكمية",
                    "الاجمالي",
                    "النوع",
                    "التاريخ",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["id"],
                    ["product"],
                    ["quantity"],
                    ["total_amount"],
                    ["type"],
                    ["date"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
}

export default {
    actions,
    mutations,
    getters,
    state,
    namespaced: true,
}