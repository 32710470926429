import axios from 'axios'
const state = {
    subjects: [],

}
const getters = {
    getSubjects(state) {
        return state.subjects
    },
}
const mutations = {
    setSubjects(state, subjects) {
        state.subjects = subjects
    },
}
const actions = {
    fetchSubjects({ commit }) {
        return new Promise(async (resolve, reject) => {
            try {
                const subjects = await axios.get("/subject", {
                    params: {
                        all: true,
                    },
                });
                commit('setSubjects', subjects.data.subjects)
                resolve()
            } catch (err) {
                reject()
            } finally {

            }
        })
    }
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
}
